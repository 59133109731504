<!--
 * @Description: What's this for
 * @Autor: WangYuan
 * @Date: 2021-06-24 10:29:52
 * @LastEditors: Tan Chao
 * @LastEditTime: 2022-06-22 11:30:28
-->
<template>
  <el-dialog
    title="选择文章分类"
    :visible.sync="visible"
    :close-on-click-modal='false'
    :append-to-body='true'
    width="45%"
  >
    <el-table
      ref="multipleTable"
      :data="list"
      tooltip-effect="dark"
      style="width: 100%"
      max-height="300"
      @selection-change="handleSelectionChange"
      @select="dialogCheck"
    >
      <el-table-column
        type="selection"
        align='center'
        header-align='center'
      >
      </el-table-column>
      <el-table-column
        prop="name"
        label="分类名"
        align='center'
        header-align='center'
      >
      </el-table-column>
      <el-table-column label="子分类">
        <template slot-scope="props">
            <div>
              <p v-if="props.row.childlist.length>0"><span v-for="(item,index) in props.row.childlist " :key="index"><el-tag class="mr4" type="info" size="small">{{item.name}}</el-tag> </span></p>
              <p v-else class="f12 f-grey">暂无</p>
            </div>
        </template>
      </el-table-column>
     
     
    </el-table>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="visible = false">取 消</el-button>
      <el-button
        type="primary"
        @click="submit"
      >确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { getGoodsType } from "@/api/goods";

export default {
  name: "GoodsConfigChoose",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Number,
      default: () => 0,
    },
  },

  created() {
    this.getList();
  },

  data() {
    return {
      visible: false,
      selectList: [],
      list: [],
      selectioned:'',//单选中的值
    };
  },

  computed: {
    ...mapGetters(["project"]),
  },

  watch: {
    show: {
      immediate: true,
      handler(newValue, oldValue) {
        this.visible = this.show;
      },
    },
    visible: {
      immediate: true,
      handler(newValue, oldValue) {
        this.$emit("update:show", newValue);
      },
    },
  },

  methods: {
     dialogCheck: function(selection, row) {
        this.$refs.multipleTable.clearSelection()
        if (selection.length === 0) { // 判断selection是否有值存在
            return
        }
        if (row) {
            this.selectioned = row
            this.$refs.multipleTable.toggleRowSelection(row, true)
        }
      },
    // 获取商城文章列表
    getList() {
      getGoodsType().then(res=>{
        console.log(res)
        if(res.code==200){
          this.list=res.data
          let selectList=[]
          if(this.value){
            this.list.forEach(item=>{
              if(item.id==this.value){
                selectList.push(item)
              }
            })
          }
           //   // 勾选回显回调方法
          setTimeout(() => {
            this.toggleSelection(selectList);
          }, 0);
        }
      })
      // let { status, list } = await ({ projectId: this.project.id });

      // if (status == "10000") {
      //   // 筛选字段
      //   this.list = list;

      //   // 根据选中列表id，筛选匹配对应数组，用于勾选回显
      //   this.selectList = this.list.filter((item) => {
      //     return this.value.includes(item.id);
      //   });

      //   // 勾选回显回调方法
      //   setTimeout(() => {
      //     this.toggleSelection(this.selectList);
      //   }, 0);
      // }
    },

    // 勾选回显
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      }
    },

    // 勾选选中
    handleSelectionChange(val) {
      // this.selectList = val;
    },

    // 提交
    submit() {
      this.$emit(
        "submit",
        this.selectioned.id
      );
      this.visible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  .el-dialog__title {
    font-size: 14px !important;
    font-weight: 600;
  }
  .el-dialog__body {
    padding: 10px 20px 30px 20px !important;
  }
}
::v-deep .el-table__header  .el-checkbox{
  display: none;
}
.wrap {
  background: #f2f2f6;
  height: 400px;
  border-radius: 5px;
}
</style>
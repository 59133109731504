/*
 * @Description: 项目管理api
 * @Autor: WangYuan
 * @Date: 2021-09-22 15:51:56
 * @LastEditors: WangYuan
 * @LastEditTime: 2022-02-10 20:15:44
 */
import request from '@/utils/request'
/**
 * 文件上传
 */
export const uploadFile = async data =>
	request({ url: '/api/common/upload?is_cdn=1', method: 'POST', data })
/**
 * 查询项目列表
 */
export const getProjectList = async params =>
	request({ url: '/api/admin/v1/wxeditor/index', method: 'GET', params })
/**
 * 查询后台跳转地址
 * **/
export const getUrl = async params =>
	request({ url: '/api/admin/v1/store/initial/dashboard', method: 'GET', params })

/**
 * 查询模板分类
 */
export const getModelType = async params =>
	request({ url: '/api/admin/v1/wxeditor/index/get_industry', method: 'GET', params })

/**
 * 查询历史保存列表
 */
export const getHistoryTemplate = async params =>
	request({ url: '/api/admin/v1/wxeditor/index/getHistoryTemplate', method: 'GET', params })

/**
 * 查询项目模板
 */
export const getModelList = async params =>
	request({ url: '/api/admin/v1/wxeditor/index/template', method: 'GET', params })
/**
* 查询项目模板详情
*/
export const getModelInfo = async params =>
	request({ url: '/api/admin/v1/wxeditor/index/template_info', method: 'GET', params })
/**
 * 新增模板
 */
export const postTemplate = async data =>
	request({ url: '/api/admin/v1/wxeditor/index/add_template', method: 'POST', data })

/**
 * 修改模板
 */
export const editTemplate = async data =>
	request({ url: '/api/admin/v1/wxeditor/index/edit', method: 'POST', data })

/**
* 生成小程序码
*/
export const createWXcode = async params =>
	request({ url: '/api/admin/v1/wxeditor/index/qr_code', method: 'GET', params })

/**
 * 根据id查询项目详情
 */
export const getProjectById = async data =>
	request({ url: 'project/getById', method: 'POST', data })

/**
 * 新增项目
 */
export const addProject = async data =>
	request({ url: 'project/add', method: 'POST', data })

/**
 * 编辑
 */
export const editProject = async data =>
	request({ url: '/api/admin/v1/wxeditor/index/edit', method: 'POST', data })

/**
 * 删除
 */
export const delProject = async data =>
	request({ url: 'project/delete', method: 'POST', data })


/**
 * 封面上传
 */
export const uploadCover = async data =>
	request({ url: '/api/common/upload?is_cdn=1', method: 'POST', data })


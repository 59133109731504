<!--
 * @Description: PC头部菜单
 * @Autor: WangYuan
 * @Date: 2021-05-19 14:07:29
 * @LastEditors: Tan Chao
 * @LastEditTime: 2022-11-07 11:46:27
-->
<template>
	<div class="topBar-fixed">
		<div class="topBar">
			<!-- 左侧内容 -->
			<div class="flex flex-center">
				<!-- <el-image class="logo pointer" fit="contain" src="./xb-logo.png" ></el-image> -->
				<img class="logo pointer" src="./xb-logo.png" alt="" />
				<el-divider direction="vertical"></el-divider>
				<span class="f14">小程序智能编辑 </span>
				<el-divider direction="vertical"></el-divider>
				<span class="f14"><span class="f-grey">当前页面：</span> </span>
				<div class="flex flex-center po-re">
					<span class="pointer flex flex-center" @click="showPage">
						<span class="max-w160 ellipsis-1 f14">{{ current_page_name || getName(-1) }}</span>
						<i class="el-icon--right pl16 f-blue" :class="morePage ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
					</span>
					<div class="pageEdit" @click="colseAll" v-show="morePage">
						<div>
							<div class="flex col-center row-between p16">
								<div class="pages-title">页面管理（{{ getSortPage().length }}）</div>
								<i class="el-icon-close pointer" @click="showPage"></i>
							</div>
							<div class="page-list">
								<div v-for="(item, index) in initPage()" :key="index" class="pages-item f14 pointer" :class="item.id == activeId ? 'active-page' : ''">
									<i class="el-icon-document-copy pr4"></i>
									<div class="flex" style="flex: 1">
										<div class="pages-name" v-if="editId == item.id">
											<el-input size="mini" v-fo @blur="changeName(pageName)" placeholder="请输入页面名称" v-model="pageName"> </el-input>
										</div>
										<div class="flex w190 flex-center pl10 pr10" v-else>
											<div class="pages-name f13" @click.stop="editPage(item.id, item.home)">
												<span class="ellipsis-1">{{ item.name }}</span>
											</div>
											<div class="flex-center">
												<span class="home-tag" v-show="item.home">主页</span>
											</div>
										</div>
									</div>
									<el-tooltip v-if="editId != item.id" class="item" effect="dark" content="修改名称" placement="top">
										<i class="el-icon-edit pointer ml20" @click.stop="editName(item.id, item.name)"></i>
									</el-tooltip>
									<div class="po-re" v-if="editId != item.id">
										<i class="el-icon-setting pointer pl10" @click.stop="showSet(index)"></i>
										<div v-if="showPopover && setIndex == index" class="set-list">
											<div class="arrow-icon"></div>
											<div class="w100" style="padding: 16px 4px">
												<div class="set-item pointer" v-if="activeId != item.id" @click.stop="editPage(item.id, item.home)">编辑此页</div>
												<div class="set-item pointer" v-if="!item.home" @click.stop="setHomePage(index)">设为主页</div>
												<div class="set-item pointer" @click.stop="copyPage(item)">复制此页</div>
												<div class="set-item pointer" @click="delPage(item.id)" v-if="!item.home && activeId != item.id">删除此页</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="p14">
							<div class="flex row-around p14" v-if="allpage > 1">
								<div class="el-icon-arrow-left pointer" :class="activepage == 0 ? 'opacity5' : ''" @click="prePage"></div>
								<div class="el-icon-arrow-right pointer" :class="activepage == allpage - 1 ? 'opacity5' : ''" @click="nextPage"></div>
							</div>
							<el-button size="medium" type="primary" style="width: 100%" @click="addPage">新建微页面</el-button>
						</div>
					</div>
				</div>
			</div>
			<!-- <span class="f14 pointer" @click="back">返回</span> -->
			<!-- 右侧内容 -->
			<div style="display: flex; align-items: center">
				<!-- <git-control /> -->
				<!-- <el-button size="small" @click="toSchema">schema 生成器</el-button>
        <el-button size="small ml10" @click="viewQr">二维码在线预览</el-button> -->
				<el-button size="small" @click="last" :disabled="activeIndex == 0" type="text"><i class="iconfont icon-seat-backward"></i></el-button>
				<el-button size="small ml10" @click="next" :disabled="activeIndex == history.length - 1 || history.length == 0" type="text"
					><i class="iconfont icon-seat-forward"></i
				></el-button>
				<el-button size="small ml40" @click="preview">预览</el-button>
				<el-button size="small ml10" type="primary" plain @click="save(0)" v-if="is_admin">保存</el-button>
				<el-button size="small ml10" type="primary" plain @click="save(1)" v-if="!is_admin">保存</el-button>
				<el-button size="small" type="primary" @click="saveEdit" v-if="!is_admin">立即发布</el-button>
				<span>
					<el-popover placement="bottom" title="" width="300" trigger="click" content="">
						<img slot="reference" style="width: 20px; margin-left: 10px" src="@/assets/image/history.png" alt="" />
						<div class="history-box">
							<div class="history-title">
								<span>历史版本管理</span>
								<span>仅存最近5版</span>
							</div>
							<div v-if="history_template.length == 0" style="height: 366px; display: flex; align-items: center; justify-content: center">
								<el-empty :image-size="100" description="暂无数据"></el-empty>
							</div>
							<div class="history-list">
								<div class="item" v-for="(item, index) in history_template" :key="index">
									<div class="i-t">
										<div class="t-1">{{ item.update_time }}</div>
										<div class="t-2" @click="restoreBtn(item.json_content)">
											<!-- <i class="el-icon-share"></i> -->
											还原
										</div>
									</div>
									<div class="i-b">
										<div class="b-1"></div>
										<div class="b-2">{{ item.nickname }}</div>
									</div>
								</div>
							</div>
						</div>
					</el-popover>
				</span>
				<!-- <el-button size="small ml10" @click="onLogout">退出</el-button> -->
			</div>
		</div>
		<real-timeView :show.sync="show"></real-timeView>
		<save-dialog ref="save"></save-dialog>
		<qr-dialog ref="qr"></qr-dialog>
	</div>
</template>

<script>
import RealTimeView from './RealTimeView.vue';
import SaveDialog from '@/components/SaveDialog';
import QrDialog from '@/components/QrDialog';
import jrQrcode from 'jr-qrcode';
import { mapGetters, mapMutations } from 'vuex';
import { editProject, createWXcode, getHistoryTemplate } from '@/api/project';
import { getInitSet } from '@/api/user';
export default {
	components: {
		RealTimeView,
		SaveDialog,
		QrDialog,
	},

	provide() {
		return {
			topBar: this,
		};
	},

	data() {
		return {
			show: false,
			db: null,
			morePage: false,
			activeId: 0,
			editId: null,
			showPopover: false,
			setIndex: null,
			logo: '',
			history: [],
			isChange: true,
			activeIndex: 0,
			id: 165,
			is_admin: false,
			activepage: 0,
			allpage: 0,
			pageName: '',
			history_template: [],
			current_page_name: '',
		};
	},
	watch: {
		project: {
			handler(newVal, oldVal) {
				console.log(newVal, oldVal);
				this.allpage = Math.ceil(newVal.pages.length / 8);
				if (this.isChange) {
					window.removeEventListener('beforeunload', this.check);
					window.addEventListener('beforeunload', this.check);
					if (this.activeIndex != this.history.length - 1) {
						console.log(this.activeIndex, this.history);
						this.history.splice(this.activeIndex + 1, this.history.length - this.activeIndex - 1);
						this.activeIndex = this.history.length - 1;
					}
					let historyItem = this.$cloneDeep(newVal);
					this.history.push(historyItem);
					if (this.history.length > 10) {
						this.history.splice(0, 1);
					}
					this.activeIndex = this.history.length - 1;
				} else {
					this.isChange = true;
				}
			},
			deep: true,
		},
	},
	computed: {
		...mapGetters(['project', 'userInfo']),
		homePage() {
			return this.project.pages.find((page) => page.home);
		},
	},
	created() {
		if (this.$route.query.is_admin) {
			this.is_admin = true;
		}
		getInitSet().then((res) => {
			console.log(res);
			if (res.code == 200) {
				if (res.data.logo) {
					this.logo = res.data.logo;
				}
				this.setUserInfo(res.data);
				this.id = res.data.id;
				//  this.getWXQr()
			}
		});
		this.getHistoryTemplateApi();
		this.allpage = Math.ceil(this.project.pages.length / 8);
	},
	mounted() {
		window.addEventListener('keydown', this.saveContent);
	},
	destroyed() {
		window.removeEventListener('beforeunload', this.check);
		window.removeEventListener('keydown', this.saveContent);
	},
	methods: {
		...mapMutations(['logout', 'setProject', 'resetProject', 'setCurPage', 'setActive', 'setUserInfo']),
		// 返回商城管理
		getHistoryTemplateApi() {
			getHistoryTemplate().then((res) => {
				if (res.code == 200) {
					this.history_template = res.data;
				}
			});
		},
		back() {
			this.$router.push({ name: 'managet' });
		},
		changeName(val) {
			let e = this.project.pages.find((item) => {
				return item.id == this.editId;
			});
			if (e.name != val) {
				e.name = val;
				this.$eventBus.$emit('changePage');
			}
			this.editId = null;
			this.pageName = '';
		},
		initPage() {
			var list = this.getSortPage();
			var real = list.slice(0 + this.activepage * 8, 8 + this.activepage * 8);
			return real;
		},
		getSortPage() {
			let arr = this.$cloneDeep(this.project.pages);
			var result = [];
			var obj = {};
			for (var i = 0; i < arr.length; i++) {
				if (!obj[arr[i].id]) {
					result.push(arr[i]);
					obj[arr[i].id] = true;
				}
			}
			return result;
		},

		prePage() {
			if (this.activepage > 0) {
				this.activepage -= 1;
			}
		},
		nextPage() {
			if (this.activepage < this.allpage - 1) {
				this.activepage += 1;
			}
		},
		saveContent(e) {
			var key = window.event.keyCode ? window.event.keyCode : window.event.which;
			if (key === 83 && e.ctrlKey) {
				this.save(1);
				e.preventDefault();
			}
		},
		check(e) {
			// if( this.history.length>1){
			e = e || window.event;
			if (e) {
				// 在这⾥阻⽌默认的刷新，弹出弹框
				e.returnValue = '关闭提⽰';
			}
			return '关闭';
		},
		last() {
			this.isChange = false;
			this.activeIndex -= 1;
			let val = this.$cloneDeep(this.history[this.activeIndex]);
			this.resetProject(val);
			let page = this.project.pages.find((page) => page.id == this.activeId);
			this.setCurPage(page);
			this.$eventBus.$emit('changeHistory');
		},
		next() {
			console.log(this.history, this.activeIndex);
			this.isChange = false;
			this.activeIndex += 1;
			let val = this.$cloneDeep(this.history[this.activeIndex]);
			this.resetProject(val);
			let page = this.project.pages.find((page) => page.id == this.activeId);
			this.setCurPage(page);
			this.$eventBus.$emit('changeHistory');
		},
		preview() {
			this.project.activeId = this.activeId;
			console.log(this.project, '预览数据---');
			this.show = true;
		},
		async getWXQr() {},
		handleClose() {},
		getName(id) {
			let page = '';
			if (id == -1) {
				page = this.project.pages.find((item) => item.home == 1);
			} else {
				page = this.project.pages.find((item) => item.id == id);
			}
			if (page) {
				return page.name;
			} else {
				return this.project.pages[0].name;
			}
		},
		editName(id, name) {
			console.log(this.editId, id);
			this.editId = id;
			this.pageName = name;
			this.colseAll();
		},
		showSet(index) {
			console.log(index);
			this.showPopover = true;
			this.setIndex = index;
		},
		colseAll() {
			this.setIndex = null;
		},
		// 新增页面
		addPage() {
			let page = {
				id: this.$getRandomCode(4),
				name: `第${this.project.pages.length + 1}页`,
				componentList: [],
				home: false,
				config: {
					angle: 180,
					backgroundColor: '',
					backgroundColor1: '',
					bgType: 1,
					colorType: 1,
					nameColor: 'rgba(0, 0, 0, 1)',
					bg_color: '#FFFFFF',
				},
			};
			this.project.pages.push(page);
			this.editPage(page.id, page.home);
		},
		delPage(id) {
			let that = this;
			let index;
			this.$confirm('此操作将永久删除该页面, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					that.project.pages.forEach((v, i) => {
						if (v.id == id) {
							index = i;
						}
					});
					that.project.pages.splice(index, 1);
				})
				.catch(() => {});

			// this.project.pages.find((page) => (page.id == id));
			// this.project.pages.splice(index,1)
			// console.log(item,index,this.activeIndex)
			// this.$forceUpdate()
		},
		// 复制页面
		copyPage(data) {
			this.morePage = true;
			let page = this.$cloneDeep(data);

			page.id = this.$getRandomCode(4);
			page.home = false;
			page.name = page.name + '(副本)';
			this.project.pages.push(page);
		},

		// 编辑页面
		editPage(id, home) {
			console.log(home);
			this.morePage = true;
			this.activeId = id;
			this.current_page_name = this.getName(this.activeId);
			if (home) {
				this.setActive(0);
				this.activeId = 0;
			} else {
				this.setActive(id);
			}
			this.morePage = false;
			this.colseAll();
			console.log('编辑页面');
			console.log(this.project.pages);
			let page = this.project.pages.find((page) => page.id == id);
			this.setCurPage(page);
			this.$eventBus.$emit('changePage');
			// this.$router.push({ name: "page-build" });
			let infoData = this.$cloneDeep(this.project);
			this.history = [{ ...infoData }];
			this.activeIndex = 0;
		},

		// 设置主页
		setHomePage(index) {
			this.project.pages.forEach((page) => (page.home = false));
			this.project.pages[index].home = true;
			this.colseAll();
			this.editPage(this.project.pages[index].id, this.project.pages[index].home);

			// this.$forceUpdate();
		},
		showPage() {
			this.morePage = !this.morePage;
			this.editId = null;
			this.colseAll();
		},
		openSave(view = false) {
			this.$refs.save.open(view);
		},

		toSchema() {
			let { href } = this.$router.resolve({
				path: '/schema',
			});
			window.open(href);
		},

		viewQr() {
			this.$confirm('如不更新，则预览为上次保存的项目数据?', '是否更新保存', {
				confirmButtonText: '更新保存',
				cancelButtonText: '不更新',
				type: 'warning',
			})
				.then(() => {
					this.openSave(true);
				})
				.catch(() => {
					this.openQr();
				});
		},

		openQr() {
			this.$refs.qr.open();
		},

		// 保存
		save(i) {
			let cloneProject = this.$cloneDeep(this.project);
			if (i) {
				cloneProject.is_template = 0;
				let arr = cloneProject.pages;
				var result = [];
				var obj = {};
				for (var i = 0; i < arr.length; i++) {
					if (!obj[arr[i].id]) {
						result.push(arr[i]);
						obj[arr[i].id] = true;
					}
				}
				cloneProject.pages = result;
				editProject(cloneProject).then((res) => {
					if (res.code == 200) {
						this.$notify({
							title: '成功',
							message: '项目保存成功',
							type: 'success',
						});
						window.removeEventListener('beforeunload', this.check);
						this.history = [];
						this.activeIndex = 0;
						this.getHistoryTemplateApi();
					}
				});
			} else {
				this.openSave(true);
			}
			// console.log(JSON.stringify(this.project))
			// editProject(this.project).then(res=>{
			//   if(res.code==200){
			//      this.$notify({
			//       title: "成功",
			//       message: "项目保存成功",
			//       type: "success",
			//     });

			// }
			// })
		},
		saveEdit() {
			let cloneProject = this.$cloneDeep(this.project);
			cloneProject.is_template = 0;
			let arr = cloneProject.pages;
			var result = [];
			var obj = {};
			for (var i = 0; i < arr.length; i++) {
				if (!obj[arr[i].id]) {
					result.push(arr[i]);
					obj[arr[i].id] = true;
				}
			}
			cloneProject.pages = result;
			editProject(cloneProject).then((res) => {
				if (res.code == 200) {
					this.$notify({
						title: '成功',
						message: '项目保存成功',
						type: 'success',
					});
					window.removeEventListener('beforeunload', this.check);
					this.activeIndex = 0;
					this.getHistoryTemplateApi();
					createWXcode({
						path: 'pages/index/index',
						scene: `?store_id=${this.id}`,
					}).then((res) => {
						this.$refs.qr.WXCode = res.data;
						this.openQr();
					});
				}
			});
		},
		// 退出
		onLogout() {
			this.$confirm('是否确定退出平台?', '退出', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			}).then(() => {
				this.logout();
				this.$router.push({ name: 'login' });
			});
		},
		restoreBtn(json_content) {
			this.$confirm('确定将界面还原成该版本吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					let obj = JSON.parse(json_content);
					let p_data = this.$cloneDeep(obj);
					this.setProject(p_data);
					this.$eventBus.$emit('changePage');
					this.setActive(0);
					this.$message({
						type: 'success',
						message: '切换成功!',
					});
				})
				.catch(() => {
					// this.$message({
					// 	type: 'info',
					// 	message: '已取消删除',
					// });
				});
		},
	},
};
</script>
<style lang="scss" scoped>
.history-box {
	position: relative;
	z-index: 999999;
	height: 407px;
	.history-title {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid rgb(219, 219, 219);
		padding-bottom: 14px;
		padding-top: 4px;
		> span:nth-child(1) {
			font-weight: bold;
			font-size: 16px;
			color: #303133;
		}
		> span:nth-child(2) {
			font-weight: 500;
			font-size: 12px;
			color: #ff7f00;
		}
	}
	.history-list {
		min-height: 366px;
		.item {
			padding: 12px;
			width: calc(100% + 24px);
			margin-left: -12px;
			.i-t {
				display: flex;
				align-items: center;
				justify-content: space-between;
				.t-1 {
					font-weight: 400;
					font-size: 14px;
					color: #303133;
				}
				.t-2 {
					display: none;
					font-weight: 400;
					font-size: 14px;
					color: #197aff;
					cursor: pointer;
				}
			}
			.i-b {
				display: flex;
				align-items: center;
				margin-top: 10px;
				.b-1 {
					width: 6px;
					height: 6px;
					background: #7ce5ff;
					border-radius: 50%;
				}
				.b-2 {
					font-weight: 400;
					font-size: 14px;
					color: #606266;
					margin-left: 8px;
				}
			}
		}
		.item:hover {
			background: #f2f3f5;
			.i-t {
				.t-2 {
					display: block;
				}
			}
		}
	}
}
.restore-dialog {
	.restore-t {
		display: flex;
		align-items: center;
		justify-content: center;
		.warning {
			background: #fa8c16;
		}
	}
	.restore-c {
		font-weight: 400;
		font-size: 18px;
		color: #303133;
		text-align: center;
	}
}
.topBar {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 56px;
	width: 100%;
	padding: 0 20px;
	background: #fff;
	border-bottom: 1px solid #ebedf0;
}

.opacity5 {
	opacity: 0.5;
	cursor: not-allowed !important;
}

.page-list {
	max-height: 400px;
}

.max-w160 {
	max-width: 160px;
}

.el-button--text:focus,
.el-button--text:hover,
.el-button--text {
	color: #626468;
}

.po-re {
	position: relative;
}

.logo {
	height: 26px;
	max-width: 200px;
}

.arrow-icon {
	position: absolute;
	left: -16px;
	top: 6px;
	width: 0;
	height: 0;
	/* 把全部的边框设置透明色 */
	border: 8px solid transparent;
	/* 总共面积为40*40,宽度由left和right撑开,高度由top和bottom撑开 */
	/* 把想拎出来的边单独设置颜色 */
	border-right-color: #fff;
	z-index: 1;
}

.set-list {
	position: absolute;
	left: 40px;
	top: -8px;
	background: #fff;
	z-index: 9;
	border-radius: 4px;
	box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}

.bb {
	border-bottom: 1px solid #ebedf0;
}

.ellipsis-1 {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
}

.set-item {
	text-align: center;
	font-size: 14px;
	padding: 12px 8px;
	color: #606266;
	z-index: 10;
	border-bottom: 1px solid #ebedf0;
}

.set-item:hover {
	color: #40a9ff;
}

.set-item:nth-last-of-type(1) {
	border-bottom: none;
	padding-bottom: 0;
}

.set-item:nth-of-type(1) {
	padding-top: 0;
}

.pages-item {
	padding: 10px 16px;
	display: flex;
	align-items: center;

	.home-tag {
		background: #1890ff;
		color: #fff;
		border-radius: 2px;
		padding: 3px 4px;
		font-size: 12px;
	}

	.el-icon-edit,
	.el-icon-setting {
		color: #606266;
		font-size: 16px;
	}

	.el-icon-edit:hover,
	.el-icon-setting:hover {
		color: #40a9ff;
	}
}

.pages-name {
	flex: 1;
	margin-right: 10px;
	height: 30px;
	display: flex;
	align-items: center;
}

.pages-item:hover {
	background: #f8fafc;
}

.active-page {
	background: #f1f8ff;
	color: #197aff;
}

.pages-title {
	font-size: 16px;
	font-weight: bold;
}

.topBar-fixed {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	z-index: 999;
}

.pageEdit {
	width: 300px;
	min-height: 420px;
	position: absolute;
	top: 33px;
	left: -80px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background: #fff;
	z-index: 9;
	border-radius: 4px;
	box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}
</style>

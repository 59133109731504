<!--
 * @Description: What's this for
 * @Autor: WangYuan
 * @Date: 2021-09-24 09:11:38
 * @LastEditors: Tan Chao
 * @LastEditTime: 2022-07-26 16:00:10
-->
<template>
  <config-item label='地址信息'>
    <el-button plain @click="openMap">{{ label }}</el-button>
    <el-dialog
      title="地址选择"
      v-if="visibity"
      :visible.sync="visibity"
      :before-close="close"
      :close-on-click-modal="false"
      width="600px"
    >
      <div>
        <div class="po-re address">
          <el-input
            v-model="rValue.place"
            class="inline-input all-flex"
            placeholder="输入详细地址"
            :trigger-on-focus="false"
            style="width:100%;margin-bottom:20px"
            @input="getNewmap"
            @blur="hiddenArr"
          >
            <el-button
              slot="append"
              icon="el-icon-location-outline"
              @click="getNewmap"
          /></el-input>
          <div
            class="po-ab addressList"
            v-show="mapArr.length > 0 && form.place != ''"
          >
            <div v-for="(v, i) in mapArr" :key="i" class="address_item">
              <div class="ellipsis-1" :title="v.value" @click="handleSelect(v)">
                {{ v.value }}
              </div>
            </div>
          </div>
        </div>
        <div id="container" style="width:100%; height:268px" />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visibity = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </config-item>
</template>

<script>
import schemaMixin from "@/mixin/schemaMixin";
import { debounce } from "@/utils/utils.js";
import md5 from "js-md5";
export default {
  name: "SchemaMap",
  mixins: [schemaMixin],
  component: {},
  data() {
    return {
      visibity: false,
      form: {
        latLng: null,
      },
      mapsearch: "",
      marker: null,
      mapArr: [],
      rValue:{}
    };
  },

  watch: {
    visibity(val){
      if(val){
        if(this.mValue){
          this.rValue = this.$cloneDeep(this.mValue)
        }
      }
    }
  },
  methods: {
    close(){
      this.rValue = {}
      this.visibity = false
    },
    getNewmap: debounce(function() {
      var queryString = this.rValue.place;
      const params = {
        key: "I64BZ-42ZC4-IW4UQ-DN7QC-XSCJT-MGBBO",
        keyword: queryString,
        region: "中国",
        output: "jsonp",
        callback: "jsonCallBack",
      };
      params.sig = this.getSig(params, "/ws/place/v1/suggestion");
      this.jsonp(
        `https://apis.map.qq.com/ws/place/v1/suggestion?key=${params.key}&keyword=${params.keyword}&region=${params.region}&sig=${params.sig}&output=${params.output}&callback=${params.callback}`
      ).then((result) => {
        if (result.status === 0) {
          const list = result.data;
          list.forEach((item) => {
            item.value = item.address + " - " + item.title;
          });
          this.mapArr = list;
          if(this.mapArr.length>0){
             const position = new window.qq.maps.LatLng(
              this.mapArr[0].location.lat,
              this.mapArr[0].location.lng
            );
            this.map.panTo(position);
            // this.marker.setPosition(that.map.getCenter());
          }
        } else {
          this.mapArr = [];
        }
      });
    }, 500),
    jsonp(url) {
      return new Promise((resolve, reject) => {
        window.jsonCallBack = (result) => {
          document.getElementsByTagName("head")[0].removeChild(JSONP);
          resolve(result);
        };
        const JSONP = document.createElement("script");
        JSONP.type = "text/javascript";
        JSONP.src = url;
        document.getElementsByTagName("head")[0].appendChild(JSONP);
      });
    },
    hiddenArr() {},
    getSig(params, url) {
      const keys = [];
      let urlParams = [];
      for (const i in params) {
        keys.push(i);
      }
      keys.sort();
      keys.forEach((item) => {
        urlParams.push(item + "=" + params[item]);
      });
      urlParams = urlParams.join("&");
      const singStr =
        url + "?" + urlParams + "ZPZi5gCqRUviN7CUTJLVhavhweF4s6xT";
      return md5(singStr);
    },
    openMap() {
      this.visibity = true;
      var script = document.createElement("script");
      script.type = "text/javascript";
      script.src =
        "https://map.qq.com/api/js?v=2.exp&key=I64BZ-42ZC4-IW4UQ-DN7QC-XSCJT-MGBBO&callback=initMap";
      document.body.appendChild(script);
      window.initMap = () => {
        this.initMap();
      };
    },
    handleSelect(item) {
      this.rValue.place = item.address + item.title;
      this.rValue.lat = item.location.lat;
      this.rValue.lng = item.location.lng;
      this.mapArr = [];
      const position = new window.qq.maps.LatLng(
        this.rValue.lat,
        this.rValue.lng
      );
      if (this.marker) {
          this.marker.setPosition(position);
        } else {
          this.marker = new window.qq.maps.Marker({
            position: position,
            map: this.map,
          });
        }
      this.map.panTo(position);
    },
    initMap() {
      console.log("-----------");
      const that = this;
      const latLng = this.rValue;
      const myLatlng = new window.qq.maps.LatLng(latLng.lat, latLng.lng);
      const myOptions = {
        zoom: 16,
        center: myLatlng,
        mapTypeId: window.qq.maps.MapTypeId.ROADMAP,
      };
      that.map = new window.qq.maps.Map(
        document.getElementById("container"),
        myOptions
      );
      // 添加监听事件  获取鼠标点击事件
      window.qq.maps.event.addListener(that.map, "click", function(event) {
        that.mapArr = [];
        if (that.marker) that.marker.setMap(null);
        that.marker = new window.qq.maps.Marker({
          position: event.latLng,
          map: that.map,
        });
        that.rValue.lat = event.latLng.lat;
        that.rValue.lng = event.latLng.lng;
      });
      window.qq.maps.event.addListener(that.map, "drag", function() {
        that.mapArr = [];
        if (that.marker) {
          that.marker.setPosition(that.map.getCenter());
        } else {
          that.marker = new window.qq.maps.Marker({
            position: that.map.getCenter(),
            map: that.map,
          });
        }
        that.rValue.lat = that.map.getCenter().lat;
        that.rValue.lng = that.map.getCenter().lng;
      });
      if (that.rValue) {
        const position = new window.qq.maps.LatLng(
          that.rValue.lat,
          that.rValue.lng
        );
        if (that.marker) {
          that.marker.setPosition(position);
        } else {
          that.marker = new window.qq.maps.Marker({
            position: position,
            map: that.map,
          });
        }
        // that.marker = new window.qq.maps.Marker({
        //   position: position,
        //   map: that.map,
        // });
      }
    },
    submit() {
      console.log(this.value);
      this.mValue=this.rValue
      this.visibity = false
    },
  },
};
</script>
<style lang="scss" scoped>
.po-re {
  position: relative;
  height: 100%;
}

* ::-webkit-scrollbar {
  display: block !important;
  width: 6px;
}
.po-ab {
  position: absolute;
}
.addressList {
  left: 0;
  top: 36px;
  width: 500px;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #ccc;
  z-index: 9999999;
  height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 20px;
}
.address_item {
  width: 500px;
  font-size: 14px;
  cursor: pointer;
  padding: 5px 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.trigger {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($color: #000000, $alpha: 0.5);
  display: none;
  align-items: center;
  justify-content: center;
  padding: 0 5%;
  z-index: 10;
  font-size: 14px;
}
.success-tag {
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 2px;
  background: conic-gradient(
    from 90deg at 50% 0,
    #13ce66 0,
    #13ce66 45deg,
    transparent 45.1deg
  );
  border-radius: 0 4px 0 0;
  border-left: 10px;
}
.play-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.upload:hover {
  .trigger {
    display: flex;
  }
  .success-tag,
  .play-icon {
    display: none;
  }
}
</style>

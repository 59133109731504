<!--
 * @Description: What's this for
 * @Autor: WangYuan
 * @Date: 2021-09-24 09:11:38
 * @LastEditors: Tan Chao
 * @LastEditTime: 2022-06-23 16:48:41
-->
<template>
  <config-item :label='label'>
    <el-slider
      v-model="num"
      v-bind="mOptions"
      show-input
      @change="changeNum"
    >
    </el-slider>
  </config-item>
</template>

<script>
import schemaMixin from "@/mixin/schemaMixin";

export default {
  name: "SchemaNumber",

  mixins: [schemaMixin],

  data() {
    return {
      defaultOptions: {
        max: 1000,
      },
      num:0
    };
  },
  created () {
    this.num=this.$cloneDeep(this.mValue)
  },
  methods: {
    changeNum(){
      this.mValue=this.num
    },
  }
};
</script>
/*
 * @Description: What's this for
 * @Autor: WangYuan
 * @Date: 2022-02-11 10:08:57
 * @LastEditors: WangYuan
 * @LastEditTime: 2022-04-08 11:00:02
 */
export default {
	// baseApi: 'https://info.lihechuanglian.com',  // 后端接口地址
	baseApi: '', // 后端接口地址
	viewUrl: 'https://h5.xiebanyun.com/#/',
	// viewUrl: 'http://192.168.77.3:8080/#/', // iframe嵌套的H5地址,本地开发可改为本地启动的H5地址
};

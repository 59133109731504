<!--
 * @Description: 物料列表 
 * @Autor: WangYuan
 * @Date: 2021-05-19 10:41:34
 * @LastEditors: Tan Chao
 * @LastEditTime: 2022-10-13 16:47:56
-->

<template>
	<div class="wrap">
		<div class="w-100 h-100 p16  bg-white scroll-y">
			<div class="type-title mb20">基础组件</div>
			<ul class="flex flex-wrap">
				<template v-for="(item, index) in $baseInitializing">
					<li :key="index" class="item" :data-component="item.component" draggable @dragstart="handleDragStart" @dragend="dragEnd">
						<i class="iconfont" :class="item.icon"></i>
						<span>{{ item.name }}</span>
					</li>
				</template>
			</ul>
			<div class="type-title mb20 mt20">高级组件</div>
			<ul class="flex flex-wrap">
				<template v-for="(item, index) in $seniorInitializing">
					<li :key="index" class="item  po-re" :data-component="item.component" draggable @dragstart="handleDragStart" @dragend="dragEnd">
						<i class="iconfont" :class="item.icon"></i>
						<span class="vip-tag" v-if="item.need_auth && (!userInfo.edition_key || userInfo.edition_key == 'base')">vip</span>
						<span>{{ item.name }}</span>
					</li>
				</template>
			</ul>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
	inject: ['control'],
	computed: {
		...mapGetters(['userInfo', 'curPage']),
	},
	data() {
		return {
			is_McTabs: false,
		};
	},
	watch: {
		'curPage.componentList': {
			handler() {
				let flag = this.curPage.componentList.find((item) => {
					return item.component == 'McTabs';
				});
				console.log(flag);
				if (flag) {
					this.is_McTabs = true;
				} else {
					this.is_McTabs = false;
				}
			},
			deep: true,
		},
	},
	created() {},
	methods: {
		// 拖拽开始
		handleDragStart(e) {
			this.control.dragWidget = this.$getNewComponent(e.target.dataset.component);
			this.control.dragstatus = true;
		},

		// 拖拽结束
		dragEnd(e) {
			this.control.h5Iframe.contentWindow.postMessage(
				{
					even: 'drop',
					params: this.control.dragWidget,
				},
				'*'
			);

			this.control.dragstatus = false;
		},
	},
};
</script>

<style lang="scss" scoped>
.wrap {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 260px; /*no*/
	overflow: hidden;
}
.po-re {
	position: relative;
}
.vip-tag {
	position: absolute;
	right: 0;
	top: 0;
	background: #197aff;
	color: #fff;
	padding: 2px 4px;
	border-radius: 10px 10px 10px 0px;
}
.type-title {
	font-size: 15px;
}
.item {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 72px; /*no*/
	margin-bottom: 8px; /*no*/
	padding: 10px 0; /*no*/
	font-size: 12px; /*no*/
	color: #666;
	cursor: pointer;
	i {
		font-size: 29px; /*no*/
		margin-top: 5px; /*no*/
		margin-bottom: 10px; /*no*/
	}

	&:hover {
		color: #fff;
		background: $color-theme;
	}
}
</style>

<!--
 * @Description: 面板
 * @Autor: WangYuan
 * @Date: 2022-01-11 20:06:56
 * @LastEditors: Tan Chao
 * @LastEditTime: 2022-10-13 16:36:56
-->
<template>
	<div class="panel" ref="panel">
		<phone-ctn>
			<div class="nav-bar" v-show="!activeId" @click.stop="setBar(1)">
				<nav-bar v-bind="project.config.navigation" :disabled="true"></nav-bar>
			</div>
			<div class="page">
				<iframe
					v-if="initIframe"
					ref="iframe"
					class="page-iframe"
					frameborder="no"
					:style="{
						height: iframeHeight + 'px',
						pointerEvents: control.dragstatus ? 'none' : 'auto',
						minHeight: '668px',
					}"
					:src="iframeUrl"
					@load="init"
				></iframe>
				<!-- 导航展示 -->

				<!-- 拖拽与iframe交互蒙层 -->
				<div
					class="page-layer"
					type="page"
					:style="{
						height: iframeHeight + 'px',
						zIndex: control.dragstatus ? '20' : null,
					}"
					@dragover="layerMove"
				>
					<control-widget-shape v-for="item in widgetInfoList" :key="item.id" :data="item" @changeCurrWidget="changeCurrWidget" @showUpgrade="showUpgrade">
						<div ref="layerWidget" class="page-layer-widget" type="widget" :id="item.id" :style="{ height: item.height + 'px' }"></div>
					</control-widget-shape>
				</div>
			</div>

			<!-- 页面设置 -->
			<el-tooltip effect="light" content="页面设置" placement="bottom">
				<div class="panel-set flex-center" @click.stop="initPage">
					<i class="el-icon-setting"></i>
				</div>
			</el-tooltip>
		</phone-ctn>
		<el-dialog title="" :visible.sync="dialogVisible" width="500px">
			<div class="flex-center flex-column">
				<div class="f26 flex col-center"><i style="color: #fa8c16" class="el-icon-warning f36 mr8"></i> 高级功能</div>
				<div class="f16 mt20">
					<div>此模块为高级功能，当前版本不支持，</div>
					<div class="mt12">如需升级请联系客服：19115975863</div>
				</div>
			</div>
			<span slot="footer" class="dialog-footer pb16">
				<el-button type="primary" @click="dialogVisible = false" size="small">我知道了</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import NavBar from '@/components/NavBar';
import ControlWidgetShape from './ControlWidgetShape.vue';
import global from '@/config/global';
import { mapGetters, mapMutations } from 'vuex';

export default {
	components: { ControlWidgetShape, NavBar },

	inject: ['control'],

	created() {
		console.log('创建页面');

		this.initIframe = true;
	},

	mounted() {
		this.control.h5Iframe = this.$refs.iframe;
		this.getMessage();
		this.$eventBus.$on('changePage', () => {
			console.log('改变');
			this.init();
		});
		this.$eventBus.$on('changeHistory', () => {
			console.log('切换操作');
			this.changeProduct();
		});
	},

	destroyed() {
		console.log('注销页面');
		this.$eventBus.$off('changePage');
		this.initIframe = false;
		window.removeEventListener('message', this.getMessageHandle);
	},

	data() {
		return {
			widgetInfoList: [],
			iframeHeight: 667,
			initIframe: false,
			dialogVisible: false,
		};
	},

	computed: {
		...mapGetters(['project', 'activeId']),
		iframeUrl() {
			return `${global.viewUrl}pagesA/build/build?operate=build&store_id=${this.project.store_id}`;
		},
	},

	// 监听物料列表发生变化，通知iframe同步更新
	watch: {
		'control.curPage.componentList': {
			handler() {
				this.messageList();
			},
			deep: true,
		},
	},

	methods: {
		...mapMutations(['resetProject']),
		setBar(i) {
			this.$emit('setBar', i);
		},
		showUpgrade() {
			this.dialogVisible = true;
		},
		changeProduct() {
			this.control.curWidget = undefined;
			this.$refs.iframe.contentWindow.postMessage(
				{
					even: 'changeCurrWidget',
					params: { id: 0 },
				},
				'*'
			);
		},
		initPage() {
			this.setBar(0);
			this.control.curWidget = undefined;
			this.$refs.iframe.contentWindow.postMessage(
				{
					even: 'changeCurrWidget',
					params: { id: 0 },
				},
				'*'
			);
		},
		// 发送信息，同步初始化iframe
		init() {
			console.log('初始化...');
			// this.$refs.panel.scrollTo(0, 0);
			this.initPage();
			this.messageInit();
			this.messageList();
		},

		// 接收iframe信息
		getMessage() {
			window.addEventListener('message', this.getMessageHandle);
		},

		getMessageHandle(e) {
			let self = this;
			let { type, params } = e.data;
			switch (type) {
				case 'setList':
					self.setList(params);
					break;
				case 'setHeight':
					self.setHeight(params);
					break;
				case 'setCurrWidget':
					self.setCurrWidget(params);
					break;
			}
		},

		// 设置页面高度
		setHeight(params) {
			this.widgetInfoList = params;
			this.iframeHeight = this.widgetInfoList.reduce((a, b) => a + b.height, 0) + 60;
			// console.log(`当前高度：${this.iframeHeight}`);
		},

		// iframe内物料列表发生变化，同步更新
		setList(params) {
			let { list } = params;
			this.control.curPage.componentList = list;
			console.log(' iframe内物料列表发生变化，同步更新', list);
		},

		// 设置选中物料
		setCurrWidget(params) {
			this.setBar(0);
			let { id } = params;
			if (this.control.curPage.componentList) {
				this.control.curWidget = this.control.curPage.componentList.find((item) => id == item.id);
			}
		},

		// 修改选中物料，并通知iframe，同步更新
		changeCurrWidget(widget) {
			console.log(widget);
			this.setCurrWidget(widget);
			this.$refs.iframe.contentWindow.postMessage(
				{
					even: 'changeCurrWidget',
					params: { id: widget.id },
				},
				'*'
			);
		},

		// 调用物料拖拽移动(节流)
		layerMove(e, index) {
			this.throttle(this.layerMoveFun, 1)(e, index);
		},

		// 物料拖拽移动,控制waiting移动
		layerMoveFun(e) {
			e.preventDefault();
			e.stopPropagation();
			if (!this.control.dragstatus) return;

			let type = e.target.getAttribute('type');
			let params = {
				type: 'page',
			};

			if (type == 'widget') {
				let [y, h] = [e.offsetY, e.target.offsetHeight];
				params = {
					id: e.target.getAttribute('id'),
					type: 'widget',
					direction: y < h / 2,
				};
			}

			this.$refs.iframe.contentWindow.postMessage(
				{
					even: 'move',
					params,
				},
				'*'
			);
		},

		// 发送信息，当前商城配置数据
		messageInit() {
			this.$refs.iframe.contentWindow.postMessage(
				{
					even: 'init',
					params: { project: this.project },
				},
				'*'
			);
		},

		// 发送信息，同步iframe种物料数组
		messageList() {
			console.log('发送信息，同步iframe种物料数组');
			console.log(JSON.parse(JSON.stringify(this.control.curPage.componentList)));
			// let project=this.$cloneDeep(this.project)
			// project.pages.forEach(item=>{
			//   if(item.id==this.control.curPage.id){
			//     item.componentList=this.control.curPage.componentList
			//   }
			// })
			// this.project=project
			// this.resetProject(project)
			this.$refs.iframe.contentWindow.postMessage(
				{
					even: 'list',
					params: this.control.curPage,
				},
				'*'
			);
		},

		// 节流
		throttle(func, wait) {
			let timeout;
			return function () {
				let context = this;
				let args = arguments;
				if (!timeout) {
					timeout = setTimeout(() => {
						timeout = null;
						func.apply(context, args);
					}, wait);
				}
			};
		},
	},
};
</script>

<style lang="scss" scoped>
.nav-bar {
	position: absolute;
	left: 0;
	bottom: -56px;
	width: 100%;
	z-index: 999;
	cursor: pointer;
	// box-shadow: 0 0 0.37333333rem 0 rgb(0 0 0 / 10%);
}
::v-deep .el-dialog__body {
	padding-bottom: 12px;
}
::v-deep .el-dialog__footer {
	text-align: center;
	padding-bottom: 34px;
}
.panel {
	position: absolute;
	left: 310px; /*no*/
	right: 410px; /*no*/
	// height: calc(100% - 5px); /*no*/
	height: 100%; /*no*/
	overflow: auto;
	width: calc(100vw - 790px);
	&::-webkit-scrollbar {
		display: none; /* Chrome Safari */
	}

	.panel-set {
		position: absolute;
		top: 10px;
		left: -70px;
		width: 40px;
		height: 40px;

		border-radius: 50%;
		background: #fff;
		// box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
		cursor: pointer;

		i {
			font-size: 22px;
			color: #b0afb2;
		}
	}

	.page {
		position: relative;
		font-size: 0;
		min-height: 669px !important;
		.page-iframe {
			position: relative;
			width: 100%;
			z-index: 10;
		}

		.page-layer {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			min-height: 669px !important;
			padding-top: 60px;
			// background: #fff;
			// box-shadow: 0 0 0.37333333rem 0 rgb(0 0 0 / 10%);
			.page-layer-widget {
				width: 100%;
				z-index: 99999;
			}
		}
	}
}
</style>

<!--
 * @Description: 页面导航组件
 * @Autor: WangYuan
 * @Date: 2021-06-10 20:05:23
 * @LastEditors: Tan Chao
 * @LastEditTime: 2022-09-22 17:20:46
-->
<template>
	<ul class="tabs" :style="getTabsStyle()">
		<li v-for="(item, index) in list" :key="index" class="tabs-item" @click="changeTab(item, index)">
			<img v-if="activeTab == index ? item.selectedIconPath : item.iconPath" :src="activeTab == index ? item.selectedIconPath : item.iconPath" class="nav-icon" alt="" />
			<!-- <i class="icon f22" :class="item.icon"></i> -->
			<span class="f13" :style="[getActiveStyle(activeTab == index, item, index)]">{{ item.text }}</span>
		</li>
	</ul>
</template>

<script>
export default {
	name: 'Navigation',
	props: {
		styles: {
			type: Object,
			default: () => {},
		},
		list: {
			type: Array,
			default: () => [],
		},
		active: {
			type: String,
		},
		disabled: {
			type: Boolean,
		},
	},

	data() {
		return {
			activeTab: 0,
		};
	},

	watch: {
		// active: {
		//   immediate: true,
		//   handler () {
		//     this.activeTab = this.active
		//   }
		// },
		// activeTab: {
		//   handler () {
		//     this.$emit('update:active', this.activeTab)
		//   }
		// }
	},

	methods: {
		// tabs 动态样式
		getTabsStyle() {
			return {
				// backgroundColor: this.style?.background || '#fff'
				backgroundColor: '#fff',
			};
		},
		getActiveStyle(flag, item, index) {
			if (flag) {
				return {
					color: `${this.styles.activeColor ? this.styles.activeColor : '#40A9FF'}`,
					fontSize: item.fontSize ? `${item.fontSize}px !important` : '10px !important',
					marginTop: (this.activeTab == index ? item.selectedIconPath : item.iconPath) ? '4px' : '0',
				};
			} else {
				return {
					color: '#9b9b9b',
					fontSize: item.fontSize ? `${item.fontSize}px !important` : '10px !important',
					marginTop: (this.activeTab == index ? item.selectedIconPath : item.iconPath) ? '4px' : '0',
				};
			}
		},
		// 切换tab
		changeTab(item, index) {
			if (this.activeTab == index) return;

			this.activeTab = index;

			// 设置模式置灰，禁止点击跳转
			if (this.disabled) return;

			this.$jump(item.jump);
		},
	},
};
</script>

<style lang="scss" scoped>
.tabs {
	display: flex;
	width: 100%;
	height: 56px;
	border-top: solid 1px #9b9b9b20;

	.tabs-item {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		flex: 1;
		background-size: contain;
		font-size: 10px;
		color: #9b9b9b;

		i {
			font-size: 20px;
			margin-bottom: 3px;
		}
	}

	.tabs-item-select {
		color: #f20c25;
	}
}
.nav-icon {
	width: 24px;
	height: 24px;
}
</style>

<!--
 * @Description: What's this for
 * @Autor: WangYuan
 * @Date: 2021-09-24 09:11:38
 * @LastEditors: WangYuan
 * @LastEditTime: 2021-11-10 14:20:53
-->
<template>
  <config-item :label='label'>
    <div>
      <div class="link f13 pt10 pointer">
        {{mValue}}
      </div>
    </div>
  </config-item>
</template>

<script>
import schemaMixin from "@/mixin/schemaMixin";
export default {
  name: "SchemaLink",
  mixins: [schemaMixin],
};
</script>
<style lang="scss" scoped>
  .link{
    color: #2686ff 
  }
</style>
<template>
  <div>
    <el-table
      ref="table"
      style="min-height:400px"
      v-loading="loading"
      size="-"
      :data="tableData"
      :header-cell-style="{ backgroundColor: '#F5F7FA' }"
      :stripe="stripe"
      sortable="custom"
      @selection-change="handleSelectionChange"
      @sort-change="handleSortChange"
      @filter-change="handleFilterChange"
      @select="choose"
      @select-all="onSelectAll"
    >
     <template slot="empty">
	      <el-row type="flex" :gutter="32"  class="flex-contain flex-main-center flex-cross-center">
          <div class="flex-contain flex-dir-top">
            <img style="width:250px;height:200px" src="@/assets/image/EmptyState_noitem.svg" alt="暂无数据">
             <div class="flex-contain flex-main-center flex-cross-center flex-dir-top">
                <div class="none_tips">暂无数据</div>
             </div>
          </div>
       </el-row>
      </template>
        <slot />
      </el-table>
      <el-pagination
        v-if="pager.total > 0"
        
        layout="slot,total,sizes,prev,pager,next"
        :page-sizes="[5, 10, 30, 50, 100]"
        :page-size="pager.pageSize"
        :total="pager.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
        <!-- <span v-if="cost" class="cost">
          查询结果:{{ pager.total }}条,查询耗时:{{ cost }}毫秒
        </span> -->
      </el-pagination>
  </div>
</template>

<script>
import request from '@/utils/request'
export default {
  props: {
    url: {
      type: String,
      required: true
    },
    params: {
      type: Object,
      default: () => { return {} }
    },
    selection_radio:{
       type: Boolean,
      default: () => { return false }
    },
    chooseId:{
      type: String,
      required: false,
      default: () => { return -1 }
    }
  },
  data() {
    return {
      stripe: false,
      tableData: [],
      loading: false,
      cost: null,
      pager: {
        currentPage: 1,
        pageSize: 5,
        total: 0,
        page: 1
      },
      activeId:this.chooseId
    }
  },
  watch: {
    loading(val) {
      this.$emit('update:loading', val)
    }
  },
  created() {
    this.load()
  },
  methods: {
    onSelectAll(){
      if(this.selection_radio==true){
         this.$refs.table.clearSelection();
      }
    },
    init(){
       this.pager= {
        currentPage: 1,
        pageSize: 5,
        total: 0,
        page: 1
      }
    },
    choose(selection,row){
      if(this.selection_radio==true){
        if(this.activeId!=row.id){
          this.$refs.table.clearSelection();
          var newArr=[]
          newArr.push(row)
          this.$refs.table.toggleRowSelection(row,true);
          this.activeId = row.id
          this.$emit('selection-change', newArr, this.tableData)
        }else{
          this.$refs.table.clearSelection();
           this.activeId = -1
          var newArr=[]
          this.$emit('selection-change', newArr, this.tableData)
        }
       
      }
        
    },
    handleFilterChange(filters) {
      this.$emit('filterHandler',filters)
    },
    handleSizeChange(val) {
      this.pager.pageSize = val
      this.load()
    },
    handleCurrentChange(val) {
      this.pager.currentPage = val
      this.pager.page = val
      this.load()
    },
    handleSelectionChange(selection) {
      if (!this.loading&&this.selection_radio==false) {
        this.$emit('selection-change', selection, this.tableData)
      }
      // else if(this.selection_radio){
      //    this.$emit('selection-change', selection, this.tableData)
      // }
    },
    handleSortChange(filed, sort) {
      if (!this.loading) {
        this.$emit('sort-change', filed, sort)
      }
    },
    async load() {
      const startTime = Date.now()
      this.loading = true
      const rs = await request({
        url: this.url,
        method: 'GET',
        params: {
          ...this.params,
          page: this.pager.page,
          limit: this.pager.pageSize
        }
      })
      console.log(rs)
      if(rs.data.total==0||rs.data.count==0||rs.data.length==0){
        this.tableData=[]
        this.pager.total=0
      }
      if(rs.data.rows||rs.data.data||rs.data.list){
         this.pager = {
          page: this.pager.page,
          total: rs.data.total,
          pageSize: this.pager.pageSize
        }
        this.tableData=rs.data.rows||rs.data.data||rs.data.list
      }
      this.activeId=-1
      console.log(this.tableData)
      this.cost = Date.now() - startTime
      this.$nextTick(() => {
        this.$emit('loaded', this.tableData)
        this.loading = false
          if(this.chooseId!=-1){
        console.log(this.chooseId);
        if(this.tableData.length>0){
          this.tableData.forEach(item=>{
            if(item.id==this.chooseId){
              console.log(item);
               this.$refs.table.toggleRowSelection(item,true);
            }
          })
        }
      }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.el-pagination {
  margin-top: 30px;
  padding-left: 0;
  .btn-prev {
    margin-left: 0;
  }
  .cost {
    font-weight: normal;
    margin-right: 10px;
  }
}
.el-table::before{
  height: 0 !important;
}
</style>

<!--
 * @Description: What's this for
 * @Autor: WangYuan
 * @Date: 2021-09-24 09:11:38
 * @LastEditors: Tan Chao
 * @LastEditTime: 2022-10-12 17:24:22
-->
<template>
  <div>
    <config-item label="字重">
      <div class="pt10">
        <el-checkbox v-model="cloneValue.bold">加粗</el-checkbox>
      </div>
    </config-item>
     <!-- <config-item label="图片标题">
       <div class="pt10">
        <Imgpond v-model="cloneValue.titleImg" :count="1" />
       </div>
    </config-item> -->
     <config-item label="下边框线">
     <div class="pt10 flex row-between f12">
      <span v-if="cloneValue.borderBottom">显示</span>
      <span v-else>隐藏</span>
       <el-switch
        v-model="cloneValue.borderBottom"
      >
      </el-switch>
     </div>
    </config-item>
    <config-item label="查看更多">
       <div>
        <div class="pt10 flex row-between f12">
         <span v-if="cloneValue.showMore">显示</span>
          <span v-else>隐藏</span>
            <el-switch
            v-model="cloneValue.showMore"
          >
          </el-switch>
        </div>
        <div v-if="cloneValue.showMore" class="title-status mt10">
           <el-radio-group v-model="cloneValue.moreStatus" size="mini">
            <el-radio :label="'info'">文字+图标</el-radio>
            <el-radio :label="'text'">仅文字</el-radio>
            <el-radio :label="'img'">仅图标</el-radio>
          </el-radio-group>
        </div>
       </div>
    </config-item>
    <config-item label="链接跳转" v-if="cloneValue.showMore">
       <div class="pt4 flex">
        <jump-select v-model="cloneValue.link"></jump-select>
       </div>
    </config-item>
  </div>
</template>

<script>
import schemaMixin from "@/mixin/schemaMixin";
export default {
  name: "SchemaTitle",
  mixins: [schemaMixin],
  data() {
    return {
      cloneValue:{}
    };
  },
  created () {
    if(this.mValue){
      this.cloneValue=this.$cloneDeep(this.mValue)
    }else{
      this.cloneValue={
        bold:true,
        showMore:false,
        moreStatus:'info',
        titleImg:null,
        link:{},
        borderBottom:false
      }
    }
    
  },
  watch: {
    "cloneValue": {
      handler(val) {
        this.mValue=val
      },
      deep: true,
    },
  },
  methods: {
    changeDisabled(e){
      this.$emit('changeDisabled',e)
    },
    changeValue(){
      this.mValue=this.cloneValue
    },
  }
};
</script>
<style lang="scss" scoped>
.title-status ::v-deep .el-radio{
  margin-right: 10px;
}
.title-status ::v-deep .el-radio__label{
  font-size: 12px;
}
</style>
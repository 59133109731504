<!--
 * @Description: What's this for
 * @Autor: WangYuan
 * @Date: 2021-09-24 09:11:38
 * @LastEditors: Tan Chao
 * @LastEditTime: 2022-09-29 14:16:36
-->
<template>
  <div>
    <config-item :label='label'>
     <el-upload
    :action="action"
    :headers="headers"
    :with-credentials="true"
    :on-success="onSuccess"
    :file-list="fileList"
    :accept="accept"
     :show-file-list="false"
    list-type="picture-card"
    >
    <div class="po-re upload">
      <div  v-if="mValue&&mValue!=''">
        <img :src="getpic(mValue)"  style="width:100%;height:100%; border-radius:4px;position: absolute;left: 0;" alt>
      <div class="success-tag">
        <i class="el-icon-check" style="color:#fff;font-size:14px" ></i>
      </div>
      <div class="play-icon">
        +
      </div>
        <div  class="trigger">
        <i class="el-icon-video-play" @click.stop="handlePreview(mValue)" style="color:#fff;font-size:20px;margin-right:16px"/>
        <i class="el-icon-delete" @click.stop="handleRemove" style="color:#fff;font-size:20px" />
       </div>
      </div>
      <span class="el-icon-plus" v-else  style="font-size:28px;color:#8c939d;left:14px;top:16px"></span>
    </div>
  </el-upload>
  </config-item>
  </div>
</template>

<script>
import schemaMixin from "@/mixin/schemaMixin";
import global from '@/config/global'
export default {
  name: "SchemaUploadVideo",

  mixins: [schemaMixin],
  data() {
    return{
       maxFileNum: this.max || 10,
      action: global.baseApi + '/api/common/upload?is_cdn=1',
      // action:'https://huique.xiebanyun.com/upload',
      fileList: [],
      accept: 'video/mp4',
      type:'',
      video:null
    }
  },
  methods: {
     getpic(src){
      if(src){
        return src+'?x-oss-process=video/snapshot,t_1000,f_jpg,w_0,h_0,m_fast,ar_auto'
      }else{
        return null
      }
    },
     mouseOver(){
      document.getElementById("tipval").style.visibility="hidden";
    },
    mouseLeave(){
      document.getElementById("tipval").style.visibility="visible";
    },
    handleRemove() {
      this.fileList=[]
      this.mValue=""
    },
    handlePreview(file) {
      // window.open(file.url)
      window.open(file.split('?')[0])
    },
    onSuccess(response, file, fileList) {
      console.log(response)
      if(response.code==1){
        this.fileList.push({
          url:response.url+'?x-oss-process=video/snapshot,t_1000,f_jpg,w_0,h_0,m_fast,ar_auto'
        })
        this.mValue=response.data.url
      }
      // if (response.url) {
      //      this.fileList.push({
      //      url: response.url+'?x-oss-process=video/snapshot,t_1000,f_jpg,w_0,h_0,m_fast,ar_auto'
      //     //  url: response.url
      //     })
      //     this.video=response.url
      //   console.log(this.type)
      //   this.$emit('input', this.fileList)
      // } else {
      //   this.$message.error(response.msg)
      // }
    }
  }
};
</script>
<style lang="scss" scoped>
.po-re{
  position: relative;
  height: 100%;
}
.trigger {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($color: #000000, $alpha: 0.5);
  display: none;
  align-items: center;
  justify-content: center;
  padding: 0 5%;
  z-index: 10;
  font-size: 14px;
}
.success-tag{
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 2px;
  background: conic-gradient(from 90deg at 50% 0,  #13ce66 0,  #13ce66 45deg, transparent 45.1deg);
  border-radius: 0 4px 0 0;
  border-left: 10px;
}
.play-icon{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.upload:hover {
  .trigger {
    display: flex;
  }
  .success-tag,.play-icon{
    display: none;
  }
}


</style>
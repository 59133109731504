<!--
 * @Description: 画板组件容器 （配置工具栏）
 * @Autor: WangYuan
 * @Date: 2021-05-24 16:37:58
 * @LastEditors: Tan Chao
 * @LastEditTime: 2022-09-20 11:31:27
-->
<template>
  <div class="shape">
    <!-- 组件工具栏 -->
    <div v-if="data.need_auth&&userInfo.edition_key=='base'" class="other-shape-tab">
      <template >
        <div>
          <div class="f14 fb color-blue">{{data.name}}</div>
         <div v-if="data.need_auth&&userInfo.edition_key=='base'" class="mt12">
           <div class="f13 f-info-grey">当前仅供预览。需升级才能使用</div>
          <div @click="showUpgrade" class="f12 pointer color-blue text-right mt15">立即升级<span class="el-icon-arrow-right color-blue"></span> </div>
         </div>
        </div>
      </template>
    </div>
    <div v-else>
       <div
      v-if="data.component != 'waiting'&&control.curWidget && control.curWidget.id == data.id"
      class="shape-tab"
    >
        <template >
        <div>
          <div class="f14 fb color-blue">{{data.name}}</div>
        </div>
      </template>
    </div>
    </div>
     
       <div
      v-if="data.component != 'waiting'&&control.curWidget && control.curWidget.id == data.id"
      class="shape-del"
    >
      <!-- 删除 -->
      <template >
        <i
          class="icon el-icon-close pointer f14"
          @click.stop="delComponent(data.id)"
        ></i>
      </template>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "shape",

  inject: ["control"],
  computed: {
    ...mapGetters(["userInfo"]),
  },
  props: {
    data: {
      type: Object,
    },
  },

  methods: {
    // 修改选中物料
    setcurComponent() {
      console.log('修改选中');
      this.$emit("changeCurrWidget", this.data);
    },
    //升级弹窗
    showUpgrade(){
      this.$emit("showUpgrade")
    },
    // 删除物料
    delComponent(id) {
      let index = this.control.curPage.componentList.findIndex(
        (item) => item.id == id
      );
      this.control.curPage.componentList.splice(index, 1);
      this.control.curWidget = undefined;
    },
  },
};
</script>

<style lang="scss" scoped>
.fb{
  font-weight: bold;
}
.color-blue{
  color: #197AFF;
}
.shape {
  position: relative;
  
  .shape-tab {
    position: absolute;
    right: -96px; /*no*/
    top: 0; /*no*/
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 80px; /*no*/
    height: 36px; /*no*/
    font-size: 12px; /*no*/
    color: #333;
    background: #ffffff;
    box-shadow: 0px 1px 16px rgba(0, 8, 60, 0.12);
    border-radius: 4px;
    &::after {
      content: "";
      position: absolute;
      right: 100%; /*no*/
      top: 7px; /*no*/
      width: 0; /*no*/
      height: 0; /*no*/
      border-width: 5px; /*no*/
      border-style: solid;
      border-color: transparent;
      margin-bottom: -1px; /*no*/
      border-right-width: 5px; /*no*/
      border-right-color: currentColor;
      color: #fff;
    }
    
  }
  .other-shape-tab{
    position: absolute;
    right: -246px; /*no*/
    top: 0; /*no*/
    width: 223px; /*no*/
    padding: 8px 20px;
    height: 85px; /*no*/
    font-size: 12px; /*no*/
    color: #333;
    background: #ffffff;
    box-shadow: 0px 1px 16px rgba(0, 8, 60, 0.12);
    border-radius: 4px;
    &::after {
      content: "";
      position: absolute;
      right: 100%; /*no*/
      top: 7px; /*no*/
      width: 0; /*no*/
      height: 0; /*no*/
      border-width: 5px; /*no*/
      border-style: solid;
      border-color: transparent;
      margin-bottom: -1px; /*no*/
      border-right-width: 5px; /*no*/
      border-right-color: currentColor;
      color: #fff;
    }
    
  }
  .shape-del{
    position: absolute;
    right: -8px; /*no*/
    top: -8px; /*no*/
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    padding: 3px;
    background: #838385;
    border-radius: 50px;
    i{
      color: #fff;
    }
  }
  .pointer{
    cursor: pointer;
  }
  .event-none {
    pointer-events: none;
  }

  .tab-icon {
    color: #969799;
    cursor: pointer;

    &:hover {
      color: #87888a;
    }
  }
}
</style>
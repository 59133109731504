<!--
 * @Description: What's this for
 * @Autor: WangYuan
 * @Date: 2021-08-17 10:18:09
 * @LastEditors: WangYuan
 * @LastEditTime: 2021-12-08 20:50:34
-->
<template>
  <div class="m20 p20 bg-white">
    <el-form
      label-position="right"
      label-width="120px"
      :model="form"
      ref="form"
      size="small"
      class="mb50"
    >
      <el-form-item label="文章名：" prop="name" :verify="{ maxLen: 30 }">
        <el-input v-model="form.name" style="width: 500px"></el-input>
        <div class="f12 f-grey">文章名称，建议30字以内</div>
      </el-form-item>
      <el-form-item label="发布时间：" prop="describe" :verify="{ maxLen: 60 }">
        <el-input v-model="form.describe" style="width: 500px"></el-input>
      </el-form-item>
      <el-form-item label="文章封面：" prop="cover" verify>
        <Imgpond v-model="form.cover" :count="1" />
        <div class="f12 f-grey">建议尺寸：800*800像素</div>
      </el-form-item>
      <el-form-item label="文章图：" prop="imgList" verify>
        <Imgpond v-model="form.imgList" :count="10" />
        <div class="f12 f-grey">
          建议尺寸：800*800像素，你可以拖拽图片调整顺序，最多上传10张
        </div>
      </el-form-item>
      <el-form-item label="文章详情：">
        <MiniMCE style="width: 800px" v-model="form.richText" />
      </el-form-item>
    </el-form>

    <div class="operation">
      <el-button size="small" class="f-white bg-theme" @click="submit()"
        >保存文章</el-button
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { addGoods, editGoods } from "@/api/goods";

export default {
  created() {
    let form = this.$route.params;
    // 价格改为元设置
    form.price = form.price ? form.price / 100 : 0;
    form.originalPrice = form.originalPrice ? form.originalPrice / 100 : 0;
    this.form = form;
  },
  data() {
    return {
      form: {},
    };
  },
  computed: {
    ...mapGetters(["project"]),
  },
  methods: {
    // 提交
    submit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.form.id ? this.edit() : this.add();
        }
      });
    },

    // 新增
    async add() {
      let data = this.formatParams({
        projectId: this.project.id,
        ...this.form,
      });

      let { status } = await addGoods(data);

      if (status == "10000") {
        this.$notify({
          title: "成功",
          message: "文章发布成功",
          type: "success",
        });
        this.$router.push({ name: "goods-manager" });
      }
    },

    // 编辑
    async edit() {
      let { status } = await editGoods(this.formatParams(this.form));

      if (status == "10000") {
        this.$notify({
          title: "成功",
          message: "文章修改成功",
          type: "success",
        });
        this.$router.push({ name: "goods-manager" });
      }
    },

    formatParams(target) {
      let data = this.$cloneDeep(target);
      // 价格为统一为分
      data.price = data.price * 100;
      data.originalPrice = data.originalPrice * 100;
      return data;
    },
  },
};
</script>

<style lang="scss" scoped>
.operation {
  position: fixed;
  bottom: 0px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 1612px;
  box-shadow: 0 -3px 5px #eee;
  background: #fff;
}
</style>

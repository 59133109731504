<!--
 * @Description: What's this for
 * @Autor: WangYuan
 * @Date: 2021-06-24 10:29:52
 * @LastEditors: Tan Chao
 * @LastEditTime: 2022-06-27 17:21:31
-->
<template>
  <el-dialog
    title="选择分类"
    :visible.sync="visible"
    :close-on-click-modal='false'
    :append-to-body='true'
    width="45%"
  >
    <el-table
      ref="multipleTable"
      :data="list"
      tooltip-effect="dark"
      style="width: 100%"
      max-height="300"
      @selection-change="handleSelectionChange"
      @select="dialogCheck"
    >
      <el-table-column
        type="selection"
        align='center'
        header-align='center'
      >
      </el-table-column>
      <el-table-column
        label="分类名称"
        align='center'
        header-align='center'
      >
        <template slot-scope="props">
         <span > {{ props.row.name }}</span>
        </template>
      </el-table-column>
    </el-table>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="visible = false">取 消</el-button>
      <el-button
        type="primary"
        @click="submit"
      >确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { getExpert} from "@/api/goods";

export default {
  name: "GoodsConfigChoose",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default:""
    }
  },

  created() {
      this.getList();
  },

  data() {
    return {
      visible: false,
      selectList: [],
      list: [],
    };
  },

  computed: {
    ...mapGetters(["project"]),
  },

  watch: {
    show: {
      immediate: true,
      handler(newValue, oldValue) {
        this.visible = this.show;
      },
    },
    visible: {
      immediate: true,
      handler(newValue, oldValue) {
        this.$emit("update:show", newValue);
      },
    },
  },

  methods: {
    // 获取列表
    async getList() {
      let res = await getExpert();
      console.log(res)
       if(res.code==200){
          this.list=res.data
          let selectList=[]
          if(this.value){
            this.value.forEach(v=>{
               this.list.forEach(item=>{
              if(item.id==v){
                selectList.push(item)
              }
            })
            })
           
          }
           //   // 勾选回显回调方法
          setTimeout(() => {
            this.toggleSelection(selectList);
          }, 0);
        }
    },
    // 勾选回显
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      }
    },

    // 勾选选中
    handleSelectionChange(val) {
      this.selectList = val;
    },

    // 提交
    submit() {
      if(this.label=='人物列表'||this.label=='会员列表'){
         this.$emit(
          "submit",
          this.selectList.map((item) => item.id)
        );
      }
     if(this.label=='供需分类'||this.label=='产品分类'){
        this.$emit(
          "submit",
          this.selectList.map((item) => item.key)
        );
     }
      this.visible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  .el-dialog__title {
    font-size: 14px !important;
    font-weight: 600;
  }
  .el-dialog__body {
    padding: 10px 20px 30px 20px !important;
  }
}
::v-deep .el-table__header  .el-checkbox{
  display: none;
}
.wrap {
  background: #f2f2f6;
  height: 400px;
  border-radius: 5px;
}
</style>
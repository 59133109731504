<!--
 * @Author: Tan Chao
 * @Date: 2022-05-17 14:58:17
 * @LastEditTime: 2022-07-01 17:24:26
-->
<template>
  <config-item :label="label">
    <GoodsConfigList :value="mValue" @edit="edit" @submit="replace"></GoodsConfigList>

    <GoodsConfigChoose
      v-if="show"
      :show.sync="show"
      :value="mValue" 
      @submit="replace"
    >
    </GoodsConfigChoose>
  </config-item>
</template>

<script>
import ConfigItem from '../../../components/global/ConfigItem.vue'
import schemaMixin from '@/mixin/schemaMixin'

export default {
  name: 'SchemaGoods',

  components: { ConfigItem },

  mixins: [schemaMixin],

  props: {
    value: {
      type: Number,
      default: () => 0
    }
  },

  data () {
    return {
      show: false,
      mValue: 0
    }
  },

  watch: {
    value: {
      immediate: true,
      handler (newValue, oldValue) {
        this.mValue = newValue
      }
    },

    mValue: {
      immediate: true,
      deep: true,
      handler (newValue, oldValue) {
        this.$emit('input', newValue)
      }
    }
  },

  methods: {
    edit (item) {
      this.show = true
    },

    replace (ids) {
      console.log(ids)
      this.mValue = ids
    }
  }
}
</script>

<style lang="scss" scoped></style>

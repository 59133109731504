<!--
 * @Author: Tan Chao
 * @Date: 2022-07-06 16:15:38
 * @LastEditTime: 2022-07-07 11:35:23
 * @LastEditors: Tan Chao
-->
<template>
    <div>
        <el-color-picker v-model="value" @change="changeColor" show-alpha></el-color-picker>
    </div>
</template>

<script>
    export default {
        name: "colorPicker",
         props: {
            value: {
            default: () => (""),
            },
        },
        data () {
            return {
                
            }
        },
        methods: {
            changeColor(){
                
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
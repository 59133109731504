/*
 * @Description: What's this for
 * @Autor: WangYuan
 * @Date: 2021-10-12 15:38:29
 * @LastEditors: WangYuan
 * @LastEditTime: 2021-11-12 17:16:16
 */
export default {
    label: 'tab配置',
    type: 'tab',
    property: '',
    value: {
        model: 'threeType',
        max: 3,
        type:"image-text"
    }
}
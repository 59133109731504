<!--
 * @Description: 实时预览弹窗
 * @Autor: WangYuan
 * @Date: 2021-07-05 17:05:21
 * @LastEditors: Tan Chao
 * @LastEditTime: 2022-07-04 15:08:51
-->
<template>
	<div class="RealTimeView">
		<el-dialog v-if="mShow" :append-to-body="true" :visible.sync="mShow" :close-on-click-modal="false" width="400px">
			<div class="phone-bg">
				<div class="real-r">
					<phone-ctn :head="false">
						<iframe id="cover" v-if="mShow" ref="iframe" class="screen" :scrolling="false" :src="iframeSrc" @load="load"></iframe>
					</phone-ctn>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import global from '@/config/global';
import { mapGetters } from 'vuex';

export default {
	name: 'RealTimeView',

	props: {
		show: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			mShow: false,
		};
	},

	computed: {
		...mapGetters(['project']),

		iframeSrc() {
			// return `${global.viewUrl}pages/index/tabbar/home?operate=realTime`;
			return `${global.viewUrl}pages/index/index?operate=realTime&store_id=${this.project.store_id}`;
		},
	},
	watch: {
		show: {
			immediate: true,
			handler(newValue, oldValue) {
				this.mShow = newValue;
			},
		},
		mShow: {
			immediate: true,
			handler(newValue, oldValue) {
				this.$emit('update:show', newValue);
			},
		},
	},

	methods: {
		load() {
			this.$nextTick(() => {
				console.log(this.project, '数据');
				setTimeout(() => {
					if (this.$refs['iframe']) {
						this.$refs['iframe'].contentWindow.postMessage(this.project, '*');
					}
				}, 1000);
			});
		},
	},
};
</script>

<style lang="scss" scoped>
* {
	box-sizing: border-box;
}
::v-deep .el-dialog {
	background: transparent;
	border: none;
	box-shadow: none;
}
::v-deep .el-dialog__headerbtn .el-dialog__close {
	font-size: 26px;
	color: #fff;
	border-radius: 30px;
}
::v-deep .el-dialog__headerbtn .el-dialog__close:hover {
	color: #fff;
}
::v-deep .el-dialog__header {
	// display: none;
}
::v-deep .el-dialog__body {
	padding: 0;
}
::v-deep .el-dialog {
	margin-top: 0 !important;
	top: 50%;
	transform: translateY(-50%);
}
::v-deep .el-dialog__headerbtn {
	right: -25px;
	top: 15px;
}
::v-deep .ctn {
	margin: 0;
	box-shadow: none;
	border-radius: 0 0 50px 50px;
	overflow: hidden;
	.ctn-height-tag {
		display: none;
	}
}
.real-r {
	width: 375px;
	height: 810px;
	border-radius: 50px;
	overflow: hidden;
}
.phone-bg {
	background-image: url('./phone-bg.png');
	background-size: contain;
	background-color: transparent;
	background-repeat: no-repeat;
	width: 401px;
	height: 830px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.screen {
	width: 375px /*no*/;
	height: 820px /*no*/;
	border: 0;

	// 隐藏滚动条
	&::-webkit-scrollbar {
		display: none; /* Chrome Safari */
	}
}

::v-deep .PhoneCtn {
	width: 414px;
	margin: 0;

	.preview-height-tag {
		display: none;
	}

	.preview-body {
		height: 667px;
		// 隐藏滚动条
	}
}
</style>

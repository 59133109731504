<!--
 * @Description: What's this for
 * @Autor: WangYuan
 * @Date: 2021-09-24 09:11:38
 * @LastEditors: Tan Chao
 * @LastEditTime: 2022-06-23 16:48:41
-->
<template>
	<config-item :label="label">
		<div class="flex row-right schema-image">
			<Imgpond v-model="mValue" :count="1" />
		</div>
	</config-item>
</template>

<script>
import schemaMixin from '@/mixin/schemaMixin';

export default {
	name: 'SchemaImage',

	mixins: [schemaMixin],

	data() {
		return {};
	},
	created() {},
	methods: {},
};
</script>
<style>
.schema-image .el-upload-list--picture-card .el-upload-list__item {
	margin: 0;
}
</style>

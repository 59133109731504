<!--
 * @Description: What's this for
 * @Autor: WangYuan
 * @Date: 2021-06-05 13:12:56
 * @LastEditors: Tan Chao
 * @LastEditTime: 2022-09-30 11:42:59
-->
<template>
	<div class="wrap">
		<div v-show="title" class="wrap-title">
			<span>{{ title }}</span>
		</div>
		<div class="wrap-body">
			<slot></slot>
			<div v-show="line" class="wrap-line"></div>
		</div>
	</div>
</template>
<script>
export default {
	name: "ConfigWrap",
	props: {
		title: {
			type: String,
			default: "",
		},
		line: {
			type: Boolean,
			default: false,
		},
	},
	data () {
		return {};
	},
};
</script>
<style lang="scss" scoped>
.wrap {
	.wrap-title {
		padding: 0px 12px;
		/*no*/
		font-size: 14px;
		/*no*/
		color: #323233;

		span {
			&::before {
				content: "*";
				color: red;
				padding-right: 2px;
			}
		}
	}

	.wrap-body {
		padding: 0px 10px 10px 10px;

		/*no*/
		.wrap-line {
			margin-top: 30px;
			height: 1px;
			background: #ebedf0;
		}
	}
}
</style>
<!--
 * @Description: 模板商城
 * @Autor: WangYuan
 * @Date: 2021-09-28 17:23:56
 * @LastEditors: Tan Chao
 * @LastEditTime: 2022-08-31 16:43:13
-->
<template>
	<div class="model-contain">
		<div class="model-title">模板库（{{ total }}）</div>
		<div class="wrap flex" style="height:100%">
			<!-- 行业分类 -->
			<ul class="wrap-head">
				<li
					v-for="(item, index) in industryList"
					:key="index"
					class="wrap-head-item"
					:class="[item.key == industry ? 'wrap-head-active' : '']"
					@click="industry = item.key"
				>
					{{ item.value }}
				</li>
			</ul>
			<!-- 模板列表 -->
			<div v-loading="loading" style="width:100%">
				<div class="p15">
					<el-input placeholder="模板搜索" prefix-icon="el-icon-search" size="small" v-model="search"> </el-input>
				</div>
				<div v-if="list.length" class="wrap-list" ref="modelList" @scroll="scrollEvent">
					<div ref="modelBox" class="pl10">
						<div v-for="model in list" :key="model.id" class="model">
							<template>
								<img class="model-img" :src="model.cover" />
								<div class="model-desc">
									<h3 class="mt5 f14">{{ model.name }}</h3>
									<!-- <div class="mt10 f12 f-grey">设计师：{{ userInfo.userName }}</div> -->
									<!-- <el-tag effect="plain" size="mini" class="mt5">{{
                  getlIndustryName(model.industry)
                }}</el-tag> -->
								</div>
							</template>

							<template>
								<div class="model-qr-bg">
									<div class="model-qr">
										<img class="w86 mb5" :src="model.wx_share" />
										<span class="preview-text">扫码预览模板</span>
									</div>
									<span class="model-btn" @click="openConfig(model)">使用模板</span>
								</div>
							</template>
						</div>
					</div>
				</div>

				<!-- 空列表 -->
				<el-empty v-else class="mt80">
					<template slot="description">
						<span class="f13 f-grey">{{ `没有此类型模板哦` }}</span>
					</template>
				</el-empty>
			</div>
		</div>
		<!-- <div class="flex-center mt50">
      <el-pagination
        background
        :page-size="paginationForm.pageSize"
        layout="total, prev, pager, next"
        :total="paginationForm.total"
        @current-change="handleCurrentChange"
      />
    </div> -->
		<el-dialog title="提示" :visible.sync="dialogVisible" width="500px" :before-close="handleClose">
			<div class="notice mt30">
				<i class="el-icon-warning f38"></i>
				<span class="f18 mt24 mb24">使用此模板后将替换全部页面及组件，确认使用？</span>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button size="small" @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" size="small" @click="replaceModel">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { getModelList, getModelInfo, getModelType, createWXcode } from '@/api/project';
import { mallTypeList } from '@/config/mall';
import global from '@/config/global';
import { debounce } from '@/utils/utils';
export default {
	created() {
		getModelType().then((res) => {
			console.log(res);
			this.industryList.push(...res.data);
			this.industry = this.industryList[0].key;
		});
		// this.getlIndustryName();
	},

	data() {
		return {
			mallIndustryList: [],
			loading: false,
			industry: null,
			list: [],
			industryList: [
				{
					key: 0,
					value: '全部',
				},
			],
			paginationForm: {
				page: 1,
				limit: 10,
			},
			search: '',
			dialogVisible: false,
			model: null,
			total: 0,
		};
	},

	computed: {
		...mapGetters(['project', 'userInfo']),
	},

	watch: {
		industry: {
			immediate: true,
			handler(val) {
				if (val || val == 0) {
					(this.paginationForm = {
						page: 1,
						limit: 10,
					}),
						this.getModel();
				}
			},
		},
		search: {
			immediate: true,
			handler() {
				if (this.industry) {
					this.reload();
				}
			},
		},
	},

	methods: {
		...mapMutations(['setProject', 'setActive']),
		// 模板商城暂时只展示自己的
		async getModel() {
			this.loading = true;
			let res = await getModelList({
				industry: this.industry,
				...this.paginationForm,
				search: this.search,
			});
			console.log(res);
			this.list = res.data.list;
			this.total = res.data.total;
			// this.$set(this.paginationForm, "total", totalCount);
			// this.list = list.filter(
			//   (item) => item.userId == "618d141848f2514904ebd07e"
			// );
			this.loading = false;
		},
		reload: debounce(function() {
			this.getModel();
		}, 500),
		scrollEvent() {
			if (
				Math.ceil(this.$refs.modelList.scrollTop + this.$refs.modelList.offsetHeight) == this.$refs.modelBox.offsetHeight ||
				Math.ceil(this.$refs.modelList.scrollTop + this.$refs.modelList.offsetHeight) > this.$refs.modelBox.offsetHeight
			) {
				console.log('触底');
				if (this.total > this.list.length) {
					this.loadMore();
				}
			}
		},
		async loadMore() {
			this.loading = true;
			this.paginationForm.page += 1;
			let res = await getModelList({
				industry: this.industry,
				...this.paginationForm,
				search: this.search,
			});
			this.list = this.list.concat(res.data.list);
			// this.$set(this.paginationForm, "total", totalCount);
			// this.list = list.filter(
			//   (item) => item.userId == "618d141848f2514904ebd07e"
			// );
			this.loading = false;
		},
		getlIndustryName(target) {
			// let industryMap = mallIndustryList.reduce((pre, cur) => {
			//   pre.set(cur.value, cur.label);
			//   return pre;
			// }, new Map());
			// return industryMap.get(target);
		},
		replaceModel() {
			this.useModel(this.model);
		},
		openConfig(model) {
			this.model = model;
			this.dialogVisible = true;
		},
		handleClose() {
			this.model = null;
			this.dialogVisible = false;
		},
		async useModel(model) {
			let res = await getModelInfo({ id: model.id });
			if (res.code == 200) {
				let p_data = this.$cloneDeep(res.data);
				p_data.is_template = 0;
				if (this.$route.query.is_admin) {
					p_data.template_id = model.id;
				} else {
					delete p_data.name;
				}
				delete p_data.id;
				delete p_data.create_time;
				delete p_data.update_time;
				delete p_data.store_id;
				delete p_data.logo;
				let obj = { ...this.project, ...p_data };
				obj.config = p_data.config;
				this.setProject(obj);
				this.$eventBus.$emit('changePage');
				this.model = null;
				this.dialogVisible = false;
				this.setActive(0);
				this.$message({
					type: 'success',
					message: '已使用选中模板!',
				});
			}
			// let { _id, id, name, userId } = this.project;

			// let map = new Map();
			// mallTypeList.map((item) => map.set(item.type, item.logo));

			// // 模板上配置相关商城数据
			// let mall = {
			//   ...this.$cloneDeep(data),
			//   ...{ _id, id, name, userId, type: "mall", logo: map.get("mall") },
			// };
		},

		getQr(id, store_id) {
			let url = `${global.viewUrl}pages/index/index?store_id=${store_id}&template_id=${id}`;
			let options = {
				padding: 0, // 二维码四边空白（默认为10px）
				width: 180, // 二维码图片宽度（默认为256px）
				height: 180, // 二维码图片高度（默认为256px）
				reverse: false, // 反色二维码，二维码颜色为上层容器的背景颜色
				background: '#ffffff', // 二维码背景颜色（默认白色）
				foreground: '#000000', // 二维码颜色（默认黑色）
			};
			return jrQrcode.getQrBase64(url, options);
		},

		handleCurrentChange(p) {
			this.$set(this.paginationForm, 'page', p);
			// this.getModelList();
		},
	},
};
</script>

<style lang="scss" scoped>
* ::-webkit-scrollbar {
	width: 5px !important;
	display: block;
}
/* 滚动槽 */
* ::-webkit-scrollbar-track {
	-webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.3);
	border-radius: 10px;
}
/* 滚动条滑块 */
* ::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background: rgba(0, 0, 0, 0.1);
	-webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
}
::v-deep .el-button {
	background: $color-theme;
}
::v-deep .el-dialog__header {
	border-bottom: 1px solid #ebeef5;
}
::v-deep .el-dialog__footer {
	border-top: 1px solid #ebeef5;
}
.notice {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	i {
		color: #fa8c16;
	}
}
.el-button--default {
	background: #fff;
}
.el-tag {
	color: $color-theme;
	border-color: $color-theme;
}
.model-contain {
	position: absolute;
	left: 0;
	top: 0;
	width: 360px;
	background: #fff;
	height: 100vh;
}
.preview-text {
	position: absolute;
	bottom: -30px;
	width: 100%;
	left: 0;
	color: #fff;
}
.model-title {
	font-size: 15px;
	font-weight: bold;
	padding: 14px 16px;
	border-bottom: 1px solid #dcdfe6;
}
.wrap {
	.wrap-head {
		margin-bottom: 10px;
		border-right: 1px solid #dcdfe6;
		.wrap-head-item {
			padding: 11px 4px;
			width: 68px;
			background: #fff;
			font-size: 13px;
			text-align: center;
			cursor: pointer;
			color: #909399;
		}

		.wrap-head-active {
			color: $color-theme;
			background: rgba(37, 137, 255, 0.1);
		}
	}

	.wrap-list {
		height: calc(100vh - 180px);
		// height: 300px;
		overflow: scroll;
		.model:nth-of-type(2n) {
			margin-left: 10px;
		}
		.model {
			position: relative;
			display: inline-block;
			width: 130px;
			height: 250px;
			// margin: 0 20px 20px 0;
			border: 1px solid #e9e9e9;
			border-radius: 4px;
			overflow: hidden;
			cursor: pointer;
			margin-bottom: 10px;
			.model-img {
				width: 100%;
			}
			.model-qr-bg {
				display: none;
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				background: rgba(0, 0, 0, 0.6);
				z-index: 99;
			}
			.model-desc {
				position: absolute;
				left: 0;
				bottom: -30px;
				width: 100%;
				height: 90px;
				padding: 10px;
				background: hsla(0, 0%, 100%, 0.95);
				font-size: 12px;
				color: #333;
				transition: all 0.3s;
				z-index: 10;
			}

			.model-qr {
				display: none;
				position: absolute;
				top: 20%;
				left: 50%;
				width: 94px;
				padding: 4px;
				border-radius: 6px;
				transform: translateX(-50%);
				background: #fff;
				font-size: 12px;
				text-align: center;
				color: #595961;
				z-index: 10;
			}

			.model-btn {
				display: none;
				position: absolute;
				bottom: 24px;
				left: 50%;
				width: 80px;
				height: 28px;
				line-height: 28px;
				border-radius: 4px;
				transform: translateX(-50%);
				background: #197aff;
				font-size: 12px;
				color: #fff;
				text-align: center;
				z-index: 10;
			}

			&:hover {
				&::after {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background: #68656530;
					z-index: 1;
					transition: all 0.3s;
				}
				.model-desc {
					// bottom: 0px;
				}
				.model-qr-bg,
				.model-btn,
				.model-qr {
					display: inline-block;
				}
			}
		}
	}
}
</style>

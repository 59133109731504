<!--
 * @Description: What's this for
 * @Autor: WangYuan
 * @Date: 2021-06-01 08:57:21
 * @LastEditors: Tan Chao
 * @LastEditTime: 2022-07-01 17:18:24
-->
<template>
	<div class="wrap pl8">
		<div class="wrap-label f13 f-grey">{{ label }}</div>
		<el-switch v-model="mValue"></el-switch>
	</div>
</template>

<script>
import schemaMixin from '@/mixin/schemaMixin';

export default {
	name: 'SchemaSwitch',

	mixins: [schemaMixin],

	props: {
		value: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			defaultOptions: {
				trueLabel: '显示',
				falseLabel: '隐藏',
			},
		};
	},
};
</script>

<style lang="scss" scoped>
.wrap {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-right: 25px; /*no*/

	.wrap-label {
		font-size: 14px; /*no*/
		line-height: 32px; /*no*/
	}
}
</style>

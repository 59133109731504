import { render, staticRenderFns } from "./SchemaComponent.vue?vue&type=template&id=9515cb28&scoped=true"
import script from "./SchemaComponent.vue?vue&type=script&lang=js"
export * from "./SchemaComponent.vue?vue&type=script&lang=js"
import style0 from "./SchemaComponent.vue?vue&type=style&index=0&id=9515cb28&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9515cb28",
  null
  
)

export default component.exports
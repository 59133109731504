<!--
 * @Description: What's this for
 * @Autor: Tan Chao
 * @Date: 2021-09-24 09:11:38
 * @LastEditors: Tan Chao
 * @LastEditTime: 2022-10-18 10:49:21
-->
<template>
  <config-item :label='label'>
    <div class="flex col-center padding-set">
      <div class="flex col-center">
        <div class="f12 f-grey">左</div>
        <el-input-number placeholder="0" v-model="nValue.left" @change="changeValue" size="mini" :min="0" :controls="false" ></el-input-number>
      </div>
       <div class="flex col-center">
        <div class="f12 f-grey">右</div>
         <el-input-number  placeholder="0" v-model="nValue.right" @change="changeValue" size="mini" :min="0" :controls="false"></el-input-number>
      </div>
       <div class="flex col-center">
        <div class="f12 f-grey">上</div>
         <el-input-number  placeholder="0" v-model="nValue.top" @change="changeValue" size="mini" :min="0" :controls="false"></el-input-number>
      </div>
       <div class="flex col-center">
        <div class="f12 f-grey">下</div>
         <el-input-number  placeholder="0" v-model="nValue.bottom" @change="changeValue" size="mini" :min="0" :controls="false"></el-input-number>
      </div>
    </div>
  </config-item>
</template>

<script>
import schemaMixin from "@/mixin/schemaMixin";

export default {
  name: "SchemaPadding",

  mixins: [schemaMixin],

  data() {
    return {
      defaultOptions: {
        max: 100,
      },
      nValue:{
        left:0,
        right:0,
        top:0,
        bottom:0
      }
    };
  },
  created () {
    console.log(this.mValue,'初始化值')
    if(!this.mValue){
      this.mValue={
        left:0,
        right:0,
        top:0,
        bottom:0
      }
    }
    this.nValue=this.$cloneDeep(this.mValue)
  },
  methods: {
    changeValue(){
      if(this.nValue.left==undefined){
        this.$set(this.nValue, 'left', 0)
      }
      if(this.nValue.right==undefined){
        this.$set(this.nValue, 'right', 0)
      }
      if(this.nValue.top==undefined){
        this.$set(this.nValue, 'top', 0)
      }
      if(this.nValue.bottom==undefined){
        this.$set(this.nValue, 'bottom', 0)
      }
      this.mValue=this.nValue
    },
  }
};
</script>
<style lang="scss" scoped>
.padding-set ::v-deep .el-input-number {
    width: 40px !important;
}
.padding-set ::v-deep .el-input-number.is-without-controls .el-input__inner{
  padding-left: 0;
  padding-right: 0;
  border-radius: 0;
  border-top: none;
  border-left: none;
  border-right: none;
}
</style>